.rev_slider {
	max-width: 1920px;
	margin: 0 auto;
}
.button-credit {
	padding: 0 5px !important;
	@include transition(all .2s ease-in-out);
	@include rounded-corners(0);
	border-color: #f0f0f0;
	&:hover {
		background: $wh;
		color: #45494e;
	}
}

.fancybox-skin {
	background: $wh;
}

button {
	&:active, &:focus {
		outline: none !important;
	}
}

#page {
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 448px);
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

#button-cart {
	padding: 0 35px;
	width: 287px;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#tab-related {
	.widget-products .owl-stage {
		padding: 0;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(0);
		background: $tc;
		@include transition(0.2s all);
		padding: 10px 15px;
		text-transform: uppercase;
		font-family: $font-family-base;
		&:hover {
			background: $black;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: none;
			&:focus, &:active {
				outline: none !important;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		@include rounded-corners(0);
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:last-child {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
				&:hover {
					.as-title {
						color: $tc;
					}
				}
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 14px;
				margin: 6px 0;
				line-height: 18px;
				@include transition(0.2s color);
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 700;
				margin-left: 90px;
				margin-bottom: 5px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
				font-size: 14px;
				line-height: 34px;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 400;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 767px) {
			right: 0;
			left: auto;
		}
		@media (max-width: 570px) {
			top: calc(100% + 7px);
			width: calc(100vw - 85px);
		}
	}
}

@media (min-width: 571px) {
	#searchtop.found {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
	.tab-content {
		padding: 0;
	}
	.tab-header {
		border-bottom: 1px solid $tg;
		text-align: center;
		span {
			display: inline-block;
			font-size: 18px;
			font-weight: 700;
			padding: 15px 30px;
			background: transparent;
			text-transform: uppercase;
			line-height: 1.1;
			color: $tc;
			border-bottom: 1px $tc solid;
			position: relative;
			top: 1px;
		}
	}
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 400;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 400;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $tc;
			border: 1px solid $tc;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.95);
			transform-origin: center center;
			width: 100%;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
			border: 3px $white solid;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $tc;
		&.agree b {
			font-weight: 400;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 400;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 2px $tc solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $tc 2px solid;
			width: 5px;
			height: 10px;
			border-right: $tc 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#button-order {
	margin-top: 15px;
}

.category_widget {
	a {
		.type1 {
			height: 500px;
			background: transparent center no-repeat;
			background-size: cover;
			margin-bottom: 30px;
			position: relative;
			h2 {
				margin: 0;
				position: absolute;
				height: 70px;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, .7);
				font-weight: 700;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				font-size: 20px;
				padding: 23px 0;
				display: inline-block;
			}
		}
	}
}

.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			width: 100px;
		}
	}
	.price-old {
		text-decoration: line-through;
		margin-right: 10px;
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 50px;
	height: 50px;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
	border: 2px $mg solid;
	@include transition(200ms all ease-in-out);
	@include rounded-corners(6px);
	svg {
		fill: $mg;
		@include transition(200ms all ease-in-out);
		margin: 15px 13px;
		width: 20px;
		height: 20px;
	}
	&:hover {
		background-color: $tc;
		border-color: $tc;
		svg {
			fill: $wh;
		}
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
	}
	input {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		margin: 5px;
		width: auto;
	}
}

h1 {
	padding-bottom: 25px;
	margin: 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	overflow-x: auto;
}

.owl-prev, .owl-next {
	position: absolute;
	top: 40px;
	padding: 10px 3px;
	width: 18px;
	height: 40px;
	color: #fff;
	@include transition(0.2s ease all);
	background: $tc;
	border: $tc 1px solid;
	&:hover {
		background-color: $wh;
		svg {
			fill: $tc;
		}
	}
	svg {
		@include transition(0.2s ease fill);
		fill: $wh;
	}
}

.owl-prev {
	left: 5px;
	background-image: url('/image/i/arrow1l.png');
}

.owl-next {
	right: 5px;
	background-image: url('/image/i/arrow1r.png');
	padding: 10px 1px 10px 4px;
}

.phbfs {
	overflow: hidden;
	.tab-content {
		& > .tab-pane {
			display: block;
			height: 0;
		}
		& > .active {
			height: auto;
		}
	}
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 20px 0 6px 0;
			}
			.mfilter-head-icon {
				@include size(25px, 20px);
				@include transition(none);
				@include notransform();
				background: url(/image/i/mfminus.png) no-repeat transparent center;
			}
			&.mfilter-collapsed .mfilter-head-icon {
				background-image: url(/image/i/mfplus.png);
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
			}
			.mfilter-counter {
				@include rounded-corners(0);
				background: #acacac;
				&:after {
					content: none;
				}
			}
			input[type="text"] {
				@include rounded-corners(0);
				height: 45px;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 5px !important;
				.ui-slider-range {
					background: $lg !important;
				}
				.ui-slider-handle {
					background: $white !important;
					height: 18px !important;
					border: 1px $lg solid !important;
					@include rounded-corners(0);
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: none;
	@include rounded-corners(4px);
	background: $xlg;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		text-transform: none;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 12px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric-items {
		display: block;
	}
	.selectric {
		@include rounded-corners(4px 4px 0 0);
		.button::after {
			@include transform(rotate(225deg));
			transform-origin: 4px 5px;
		}
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 30px;
	right: 0;
	background: $xlg;
	border: none;
	z-index: -1;
	@include rounded-corners(0 0 4px 4px);
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
		@include transition(background 0.2s ease-in-out);
	}
	li {
		display: block;
		padding: 10px;
		color: $mg;
		cursor: pointer;
		line-height: 14px;
		white-space: nowrap;
		&:hover {
			color: $bk;
			background: $lg;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: $bk;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.product-compare-header {
	position: absolute;
	top: 185px;
	right: 0;
	background: $tc;
	border: 1px solid $tc;
	padding: 10px 7px;
	z-index: 999;
	text-align: center;
	@include transition(0.2s all);
	width: 90px;
	svg {
		width: 38px;
		height: 38px;
		fill: #fff;
	}
	&:hover {
		background: $wh;
		a {
			color: $tc;
		}
		svg {
			fill: $tc;
		}
	}
	a {
		color: $white;
	}
	p {
		margin: 0;
		&#compare-total {
			font-weight: bold;
			line-height: 14px;
			font-size: 12px;
		}
	}
	.badge {
		color: $white;
		position: absolute !important;
		top: 5px !important;
		right: 10px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $tc;
	}
}

.container-inside {
	margin-right: 10px;
}

.widget-products {
	.owl-stage {
		padding: 15px 0;
	}
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-next, .owl-prev {
		top: calc(50% - 40px);
	}
}

#request-on-order-form .btn-buy-product {
	background: $tc !important;
	padding: 0 37px !important;
	color: $white !important;
	@include transition(0.2s all);
	&:hover {
		background: $bk !important;
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.page-information {
	img {
		max-width: 100%;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		display: flex;
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		display: flex;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

#checkout-form {
	.has-feedback .form-control-feedback {
		top: 26px;
		right: 10px
	}
	#payment-form {
		display: none
	}
	#shipping_methods, #payment_methods {
		.radio {
			margin-left: 20px
		}
	}
}

.holiday_works {
	text-decoration: underline dashed red;
	border: none !important;
	margin-left: 0 !important;
	top: 9px;
	right: calc(50vw - 300px);
	position: absolute;
}

.wall {
	@media (min-width: 992px) {
		display: none;
	}
	position: absolute;
	background: rgba(0, 0, 0, .5);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 999;
	@include transition(all .2s ease-in-out);
	.menutoggle {
		width: 60px;
		display: inline-block;
		position: fixed;
		top: calc(50% - 25px);
		left: calc(85% - 25px);
		svg {
			fill: $wh;
			width: 60px;
			height: 60px;
		}
	}
}

.row-offcanvas.active {
	.wall {
		visibility: visible;
		opacity: 1;
	}
}

.text_under_title {
	font-size: 24px;
	color: $tc;
}

#review {
	table {
		margin-bottom: 15px;
	}
	.rating {
		margin-left: 15px;
	}
}

#form-review-container {
	.recaptcha_container label {
		display: none;
	}
}

.rating, .stars {
	.fa {
		color: #ffbb00;
	}
}

.prod_rating {
	.custom-color {
		cursor: pointer;
	}
}

#is_ip .radio_custom .input-control {
	margin-right: 15px;
}

.information-direktoru {
	#cont-form {
		input[type="text"],
		#input-enquiry {
			font-style: normal;
		}
		.type-mail-send {
			list-style: none;
			padding: 0;

			.input-control {
				text-transform: none;
				padding-top: 0;
				padding-left: 28px;
				font-weight: bold;
				font-size: 18px;

				input:checked ~ {
					.input-control__indicator {
						&:after {
							opacity: 1;
							filter: alpha(opacity=100);
						}
					}
				}
				&__indicator {
					background-color: #fff;
					width: 20px;
					height: 20px;
					user-select: none;
					text-align: center;
					top: 2px;
				}
			}
		}
	}
}

.service-center {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	line-height: 18px;
	margin-bottom: 14px;

	&__item {
		max-width: 240px;
		width: 100%;
		padding: 8px;

		&-wrapper {
			margin-bottom: 5px;

			.title {
				font-weight: 600;
			}
		}
	}
}