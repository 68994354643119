//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 0;
	border-radius: $border-radius-base;

	> li {
		display: inline;
		> a,
		> span {
			position: relative;
			float: left;
			margin-right: 8px;
			line-height: 32px;
			width: 20px;
			height: 34px;
			text-decoration: none;
			text-align: center;
			color: $bk;
			font-size: 16px;
		}
		&:first-child {
			> a,
			> span {
				margin-left: 0;
			}
		}
	}

	> li > a,
	> li > span {
		&:hover,
		&:focus {
			color: $tc;
		}
	}

	> .active > a,
	> .active > span {
		&,
		&:hover,
		&:focus {
			color: $tc;
			text-decoration: none;
			cursor: default;
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			color: $pagination-disabled-color;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border;
			cursor: $cursor-disabled;
		}
	}
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
	@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
	@include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}

.paging .pull-right {
	margin-top: 6px;
}