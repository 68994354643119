$f: "PT Sans", sans-serif !default;
$fa: "FontAwesome" !default;
$bk: #45494e !default;
$black: $bk !default;
$wh: #fff !default;
$tc: #6FB933 !default;
$xlg: #f2f2f2 !default;
$lg: #ececec !default;
$tg: #424242 !default;
$bc: #ececec !default;
$dg: #333 !default;
$inactive: #aaa !default;
$mg: #4e4e4e !default;

@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "fonts";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "app/animation";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/verticalmenu.scss";
@import "opencart/modules/owl.scss";
@import "opencart/modules/sphinx";

@import "app/custom";
@import "opencart/responsive";