.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		li {
			padding: $padding-small-vertical 0;
			padding-left: 10px;
			margin-right: 10px;
			margin-left: 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				color: $bk;
				font-size: 13px;
				text-transform: uppercase;
				@include transition(fill 200ms ease-in-out);
				&:hover {
					color: $tc;
				}
				img {
					max-width: calc(100% - 45px);
				}
				span {
					flex: 0 0 100%;
					padding-top: 10px;
					display: inline-block;
					text-align: center;
					font-weight: 700;
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

#i3dtoggle {
	text-align: center;
	height: auto;
	padding-top: 5px;
	width: 130px;
	white-space: normal;
	line-height: 16px;
	padding-bottom: 5px;
	margin-top: 20px;
	svg {
		width: 40px;
		display: inline-block;
		fill: $wh;
		@include transition(fill 200ms ease-in-out);
		height: 30px;
	}
	span {
		font-size: 18px;
		cursor: pointer;
		display: inline-block;
	}
	&:hover {
		svg {
			fill: $tc;
		}
	}
	@media (max-width: $screen-xs-max) {
		display: none;
	}
}

#i3d {
	.modal-dialog {
		width: 700px;
	}
	.modal-header {
		height: 35px;
		border: 0;
	}
	.modal-body {
		height: 520px;
	}
}

#i3dbody {
	ol {
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
		margin: 0;
		padding: 0;
		img {
			position: absolute;
			top: 0;
			height: auto;
			&.previous-image {
				visibility: hidden;
			}
			&.current-image {
				visibility: visible;
			}
		}
	}
	.basel-threed-view {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		cursor: ew-resize;
	}
	.spinner {
		width: 60px;
		display: block;
		margin: 0 auto;
		height: 30px;
		background: #333;
		background: rgba(0, 0, 0, 0.7);
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		span {
			font-size: 12px;
			font-weight: bolder;
			color: #fff;
			text-align: center;
			line-height: 30px;
			display: block;
		}
	}
	.nav_bar {
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 11;
		a {
			display: block;
			width: 32px;
			height: 32px;
			float: left;
			background: url(/image/i/sprites.png) no-repeat;
			text-indent: -99999px;
			&.nav_bar_play {
				background-position: 0 0 !important;
			}
			&.nav_bar_previous {
				background-position: 0 -73px !important;
			}
			&.nav_bar_stop {
				background-position: 0 -37px !important;
			}
			&.nav_bar_next {
				background-position: 0 -104px !important;
			}
		}
	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
	.blackfriday {
		bottom: 10px;
		left: 10px;
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

.product-filter {
	color: $product-filter-color;
	font-size: $product-filter-font-size;
	font-weight: 400;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 400;
		font-size: 24px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 0;
	}
	.product-block {
		display: flex;
		border: 1px solid #eee;
		margin: 10px 0;
		border-radius: 0;
		.name {
			font-weight: 700;
			font-size: 18px;
		}
		.block-img {
			width: 30%;
		}
		.image {
			padding: 0;
		}
		.bottom {
			padding: 0 20px;
			justify-content: left;
			.quickview {
				margin-left: 0;
			}
			.action {
				display: inline-block;
				padding: 4px 0;
				margin-left: 15px;
			}
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.products-block {
	margin: 0 -15px;
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
		.product-block {
			width: calc(100% / 4 - 33px);
		}
	}
	.product-block {
		display: inline-block;
		margin: 15px;
		width: calc(100% / 4 - 33px);
		border: 1px solid $bc;
		@include rounded-corners(6px);
		.name {
			text-align: center;
		}
		.price {
			text-align: center;
		}
		.cart {
			padding: 0;
			.btn {
				width: 125px;
			}
		}
		.bottom {
			@include transition (all 200ms ease-in-out);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			background-color: transparent;
			text-align: center;
			padding: 0 15px 30px 15px;
		}
		&:hover {
			border-color: $tc;
			.quickview, .compare {
				opacity: 1;
				visibility: visible;
			}
		}
		.quickview, .compare {
			opacity: 0;
			visibility: hidden;
			@include transition (all 200ms ease-in-out);
		}
	}
}

.product-special, .product-search {
	.product-grid {
		.product-block {
			width: calc(100% / 4 - 33px);
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 30px;
		margin-top: 0;
		font-weight: 700;
		text-transform: uppercase;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
			.owl-next {
				right: 2px;
			}
			.owl-prev {
				left: 2px;
			}
			.owl-prev, .owl-next {
				height: auto;
				top: 20px;
				bottom: 20px;
				padding-left: 1px;
				opacity: 0;
				visibility: hidden;
				@include transition (all 200ms ease-in-out);
				svg {
					top: calc(50% - 8px);
					position: absolute;
				}
			}
		}
		&:hover {
			.owl-prev, .owl-next {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
		margin-bottom: 0;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 0);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
			&:hover, &:focus {
				color: $theme-color;
			}
		}
		.quantity-number {
			input {
				@include size(40px, 40px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				@include box-shadow(none);
				padding: 0;
				border: none;
				color: $bk;
				font-size: 16px;
				font-style: normal;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 16px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			@include size(42px,42px);
			padding: 8px 7px;
			svg {
				fill: $wh;
				@include transition(.2s fill ease-in-out);
			}
			&:hover {
				svg {
					fill: $tc;
				}
			}
			a.btn-sm {
				padding: 0 19px;
			}
		}
		.cart {
			padding-bottom: 0;
			display: flex;
			gap: 10px;
			flex-wrap: wrap;
		}
	}

}

.zoomWindowContainer .zoomWindow {
	left: 500px;
	background-color: $wh !important;
}

.h1_container {
	font-size: 26px;
	h1 {
		margin: 0 auto;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 12px 15px 42px 15px;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

.category-description {
	h2, h3 {
		font-size: 26px;
		margin: 20px 0 10px;
	}
	p {
		font-size: 18px;
		line-height: 1.5;
	}
	ul {
		font-size: 18px;
	}
}