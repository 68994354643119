.product-block {
	.block-img {
		position: relative;
		overflow: hidden;
	}
	.action {
		text-align: center;
		> div {
			display: inline-block;
			position: relative;
			margin-bottom: 5px;
			span {
				display: none;
			}
		}
	}

}

.grayscale-gray {
	filter: grayscale(100%);
	-webkit-filter: grayscale(1); /* For Webkit browsers */
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: gray; /* For IE 6 - 9 */
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
}

.grayscale-color {
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
	-ms-filter: grayscale(0%);
	-o-filter: grayscale(0%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
}

/* effect-v1 */
.effect-v1 {
	background-color: #000;
	overflow: hidden;
	position: relative;
	&:before {
		border: 1px solid #ffffff;
		bottom: 20px;
		content: "";
		left: 20px;
		position: absolute;
		right: 20px;
		top: 20px;
		@include scale (1.1);
		@include opacity (0);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
		z-index: 1;
	}
	img {
		@include scale (1.12);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}
	.action {
		@include scale (1.12);
		@include opacity(0);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}

	/* style for hover */
	&:hover {
		&:before {
			@include opacity(1);
			@include scale (1);
		}
		img {
			@include opacity(0.5);
			@include scale (1);
		}
		.action {
			@include scale (1);
			@include opacity(1);
		}
	}
}

/* effect-v2*/
.effect-v2 {
	background-color: #000;
	overflow: hidden;
	position: relative;
	&:before {
		border: 1px solid #ffffff;
		bottom: 20px;
		content: "";
		left: 20px;
		position: absolute;
		right: 20px;
		top: 20px;
		@include translate3d (-20px, 0px, 0px);
		@include opacity(0);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
		z-index: 1;
	}
	img {
		@include scale (1, 1);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}
	.action {
		@include translate3d(-10px, 0px, 0px);
		@include opacity(0);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}
	/* style for hover */
	&:hover {
		&:before {
			@include opacity(1);
			@include translate3d(0px, 0px, 0px);
		}
		img {
			@include opacity(0.7);
			@include scale (1.2, 1);
		}
		.action {
			@include translate3d(0px, 0px, 0px);
			@include opacity(1);
		}
	}
}

/* effect-v3*/
.effect-v3 {
	background-color: #000;
	overflow: hidden;
	position: relative;
	&:before, &:after {
		bottom: 20px;
		content: "";
		left: 20px;
		opacity: 0;
		position: absolute;
		right: 20px;
		top: 20px;
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
		z-index: 1;
	}
	&:before {
		border-bottom: 1px solid #ffffff;
		border-top: 1px solid #ffffff;
		@include scale(0, 1);
	}
	&:after {
		border-left: 1px solid #ffffff;
		border-right: 1px solid #ffffff;
		@include scale(1, 0);
	}
	img {
		@include opacity(1);
		@include transition (opacity 0.35s ease 0s);
	}
	.banner-title {
		@include translate3d(0px, -20px, 0px);
		@include transition (transform 0.35s ease 0s);
	}
	.action {
		@include opacity(0);
		@include translate3d(0px, 40px, 0px);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}

	/* style for hover */
	&:hover {
		&:before,
		&:after {
			@include opacity(1);
			@include scale(1);
		}
		img {
			@include opacity(0.5);
		}
		.banner-title, .action {
			@include opacity(1);
			@include translate3d(0px, 0px, 0px);
		}
	}
}

/* effect-v4*/
.effect-v4 {
	background-color: #000;
	overflow: hidden;
	position: relative;
	&:before {
		border: 1px solid #ffffff;
		bottom: 20px;
		@include box-shadow(0 0 0 30px rgba(255, 255, 255, 0.2));
		content: "";
		left: 20px;
		opacity: 0;
		position: absolute;
		right: 20px;
		top: 20px;
		@include scale(1.4);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
		z-index: 1;
	}
	img {
		@include opacity(1);
		@include transition (opacity 0.35s ease 0s);
	}
	.banner-title {
		@include scale(1);
		@include transition (transform 0.35s ease 0s);
	}
	.action {
		@include opacity(0);
		@include scale(1.2);
		@include transition(opacity 0.35s ease 0s, transform 0.35s ease 0s);
	}

	/* style for hover */
	&:hover {
		&:before {
			@include opacity(1);
			@include scale(1);
		}
		img {
			@include opacity(0.5);
		}
		.banner-title {
			@include scale(0.95);
		}
		.action {
			@include opacity(1);
			@include scale(1);
		}
	}
}

/* effect-v5*/
.effect-v5 {
	background-color: $white;
	overflow: hidden;
	position: relative;
	text-align: center;
	img {
		backface-visibility: hidden;
		@include opacity(1);
		@include transition (opacity 1s ease 0s, transform 1s ease 0s);
	}
	.action {
		transform: translate3d(-150%, 0px, 0px);
		@include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);

		&:first-child {
			@include transition-delay(0.15s);
		}
		&:nth-of-type(2) {
			@include transition-delay (0.1s);
		}
		&:nth-of-type(3) {
			@include transition-delay (0.05s);
		}
	}

	/* style for hover */
	&:hover {
		img {
			@include opacity(0.8);
			transform: scale3d(1.1, 1.1, 1);
		}
		.action {
			@include opacity(1);
			transform: scale3d(1.1, 1.1, 1);
			transform: translate3d(0px, 0px, 0px);

			&:first-child {
				@include transition-delay (0s);
			}
			&:nth-of-type(2) {
				@include transition-delay (0.05s);
			}
			&:nth-of-type(3) {
				@include transition-delay (0.1s);
			}
		}

	}
}

/* effect-v6*/
.effect-v6 {
	background-color: #000;
	overflow: hidden;
	position: relative;

	img {
		@include opacity(1);
		@include transition (opacity 0.35s ease 0s);
	}
	.action {
		transform: translate3d(0px, -100px, 0px);
		transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
		opacity: 0;
		margin-top: 20px;
	}

	/* style for hover */
	&:hover {
		img {
			@include opacity(0.5);
		}
		.action {
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		}

	}
}

/* effect-v7*/
.effect-v7 {
	overflow: hidden;
	position: relative;
	&:before {
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		@include transition(all 0.4s cubic-bezier(0.94, 0.85, 0.1, 0.62) 0s);
		z-index: 1;
		border: 200px double rgba(0, 0, 0, 0);
		@include opacity(1);
		visibility: visible;
	}

	/* style for hover */
	&:hover {
		&:before {
			@include opacity(0);
			border: 0 double rgba(255, 255, 255, 0.7);
			visibility: hidden;
		}
	}
}

/* effect-v8*/
.effect-v8 {
	overflow: hidden;
	position: relative;
	&:before {
		border-bottom: 50px solid rgba(255, 255, 255, 0.4);
		border-top: 50px solid rgba(255, 255, 255, 0.4);
		content: "";
		height: 100%;
		left: 0;
		@include opacity(0);
		position: absolute;
		top: 0;
		transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
		-o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
		-webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
		-moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
		-ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
		@include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
		transform-origin: 50% 50% 0;
		width: 100%;

	}

	/* style for hover */
	&:hover {
		&:before {
			@include opacity(1);
			transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
			-o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
			-webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
			-moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
			-ms-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 4, 1);
		}
	}
}

/* effect-v9*/
.effect-v9 {
	overflow: hidden;
	position: relative;
	&:before {
		border: 50px solid rgba(0, 0, 0, 0);
		@include border-top-radius (50px);
		@include border-bottom-radius (50px);
		box-sizing: border-box;
		cursor: pointer;
		display: inline-block;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		top: 0;
		content: "";
		@include opacity (1);
		@include size(100px, 100px);
		@include scale(7);
		@include transition (all 0.3s ease-in-out 0s);
		visibility: visible;
		z-index: 1;
	}

	/* style for hover */
	&:hover {
		&:before {
			border: 0 solid rgba(0, 0, 0, 0.7);
			@include opacity (0);
			visibility: hidden;
		}
	}
}

/* effect-v10*/
.effect-v10 {
	overflow: hidden;
	position: relative;
	&:before {
		background-color: rgba(255, 255, 255, 0.2);
		display: block;
		@include size(100%, 100%);
		left: -100%;
		@include opacity (0);
		position: absolute;
		top: 0;
		@include transition (all 0.3s ease 0s);
		content: "";
		z-index: 1;
	}

	/* style for hover */
	&:hover {
		&:before {
			left: 0;
			@include opacity (1);
		}
	}
}

.effect-v11 {
	overflow: hidden;
	position: relative;

	img {
		@include opacity(1);
		@include transition (opacity 0.35s ease 0s);
	}
	.action {
		transform: translate3d(0px, -100px, 0px);
		transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;
		opacity: 0;
	}

	/* style for hover */
	li:hover {
		img {
			@include opacity(0.8);
		}
		.action {
			opacity: 1;
			transform: translate3d(0px, 0px, 0px);
		}

	}
}

.icon-effect-v1 {
	img {
		transform: scale(1);
		transition: transform 0.2s ease 0s;
	}
	li:hover {
		img {
			transform: scale(0.8);
		}
	}
}