@font-face {
	font-family: 'PT Sans';
	src: url('/fonts/ptsans/subset-PTSans-Regular.eot');
	src: url('/fonts/ptsans/subset-PTSans-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/ptsans/subset-PTSans-Regular.woff2') format('woff2'),
	url('/fonts/ptsans/subset-PTSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: url('/fonts/ptsans/subset-PTSans-Italic.eot');
	src: url('/fonts/ptsans/subset-PTSans-Italic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/ptsans/subset-PTSans-Italic.woff2') format('woff2'),
	url('/fonts/ptsans/subset-PTSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'PT Sans';
	src: url('/fonts/ptsans/subset-PTSans-Bold.eot');
	src: url('/fonts/ptsans/subset-PTSans-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/ptsans/subset-PTSans-Bold.woff2') format('woff2'),
	url('/fonts/ptsans/subset-PTSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PT Sans';
	src: url('/fonts/ptsans/subset-PTSans-BoldItalic.eot');
	src: url('/fonts/ptsans/subset-PTSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('/fonts/ptsans/subset-PTSans-BoldItalic.woff2') format('woff2'),
	url('/fonts/ptsans/subset-PTSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}