@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
}

@media (max-width: $screen-md-max) {
	.products-block {
		.product-label {
			&.free-delivery,
			&.presale-prepare-free {
				font-size: 10px;
			}
		}
	}
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 19px 13px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 350px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 200px;
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.holiday_works {
		right: calc(50vw - 350px);
	}
	.pav-megamenu {
		.navbar-nav {
			padding: 0 10px;
			& > li > a {
				padding: 19px 10px;
			}
		}
	}
	.logo-store a span {
		font-size: 11px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.product-grid .product-block .cart .btn {
		width: 110px;
	}
	#searchtop input.form-control {
		padding: 9px 5px;
	}
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.product-special .product-grid .product-block, .product-search .product-grid .product-block {
		width: calc(100% / 3 - 33px);
	}
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.header-top .btn-group {
		margin-right: 10px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#powered {
		.inner {
			flex-wrap: wrap;
		}
		.copyright, .payment {
			text-align: center;
		}
		.payment {
			img {
				max-width: 490px;

				&:first-child {
					display: none;
				}
			}
		}
		.payment-img {
			text-align: center;
		}
	}
	.payment2row {
		display: block;
	}
	.product-grid, .products-owl-carousel {
		.product-block .compare {
			visibility: visible;
			opacity: 1;
		}
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3 - 33px);
			.action > div {
				padding-right: 2px;
			}
		}
	}
	.header-top {
		.topcontacts, .topcontacts.phone {
			flex: 0 0 250px;
			padding-left: 0;
			a {
				color: $wh !important;
			}
			.media-title {
				padding: 7px 10px;
			}
			.media-body {
				span {
					line-height: 17px;
					vertical-align: baseline;
					font-size: 12px;
				}
			}
		}
		.topcontacts.worktime {
			margin-left: 30px;
		}
		.header-cart {
			position: absolute;
		}
	}
	.header-bottom {
		.main-menu {
			order: 1;
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $tc;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
				}
			}
		}
		.nav-search {
			order: 3;
		}
		.logo-container {
			order: 2;
			flex: 1 1 auto;
			text-align: center;
		}
	}
	.logo-store a span {
		font-size: 12px;
	}
	.holiday_works {
		right: calc(50vw - 225px);
	}
	.quickview, .zoom {
		display: none !important;
	}
	body .mfilter-free-container {
		top: 148px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		#mfilter-free-container-0 {
			padding: 0 5px !important;
			overflow: auto !important;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border-color: $theme-color;
			&:before {
				content: '\f0b0';
				font-family: 'Fontawesome';
				color: $white;
				font-size: 24px;
				line-height: 23px;
				padding: 3px;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 15px;
	}
	.h1_container h1 {
		padding: 0 15px 21px 15px;
	}
	.product-compare-header {
		border: none;
		padding: 6px;
		width: 60px;
		position: absolute;
		top: 0;
		right: calc(((100vw - 780px) / 2) + 80px);
		background: transparent;
		&:hover {
			background: transparent;
		}
		a {
			color: $bk;
		}
		svg {
			width: 28px;
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 10px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.pagescarousel .pc_wrapper {
		flex-wrap: wrap;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 100%;
			}
			&.pc_image {
				order: 1;
				padding: 0;
			}
			&.pc_text {
				order: 2;
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.home-featured-category {
		.list-arrow {
			.owl-stage {
				li {
					a {
						.title {
							font-size: 20px;
							line-height: 25px;
						}
					}
				}
			}

		}
	}
	.product-set-group {
		.group_item {
			width: 100%;
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
}

@media (max-width: $screen-xs-max) {
	#coupon_form {
		padding-left: 0;
		padding-right: 0;
		.form-group {
			flex-wrap: wrap;

			span, button {
				width: 100%;
			}
			span {
				margin-top: 8px;
			}
		}
		.alert-info {
			margin-right: 0;
			margin-left: 0;
		}
	}
	.products-block {
		.product-label {
			&.free-delivery,
			&.presale-prepare-free {
				font-size: 12px;
			}
		}
	}
	.container-inside {
		margin-right: 0;
	}
	.product-special .product-grid .product-block, .product-search .product-grid .product-block {
		width: calc(100% / 2 - 33px);
	}
	.product-compare-header {
		right: 75px;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 32px);
		}
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding-left: 0 !important;
				padding-right: 0;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-top {
		.headercart {
			flex: 1 1 200px;
		}
		.topcontacts, .topcontacts.phone {
			flex: 1 1 235px;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
		.nav-search {

		}
	}
	.holiday_works {
		top: 43px;
		right: auto;
		left: 23px;
	}
	.header-voltage {
		display: none;
	}
	#powered .inner {
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
		}
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
		.box .box-heading {
			margin-bottom: 5px;
			margin-top: 20px;
		}
	}
	.breadcrumbs .breadcrumb {
		margin: 0 -15px;
	}
	.service-center {
		&__item {
			max-width: 100%;
		}
	}
	.product-set-group {
		column-count: 2;
		display: block;
		row-gap: 8px;

		.group_item {
			display: block;
			margin-bottom: 8px;
		}
	}
}

@media (max-width: 637px) {
	.fancybox-skin {
		padding: 5px !important;
	}
	.fancybox-wrap {
		width: 280px !important;
	}
	.fancybox-inner {
		width: 272px !important;
	}
	.holiday_table {
		width: 272px !important;
		tr td {
			font-size: 14px;
			line-height: 22px;
		}
	}
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 13px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
}

@media (max-width: 570px) {
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.worktime {
		display: none;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
		margin-right: 15px;
	}
	#searchtop {
		border: 0;
		background: transparent;
		.btn-search svg {
			fill: $tc;
		}
		input.form-control {
			opacity: 0;
			visibility: hidden;
			border: 1px $lg solid;
			width: 40px;
			position: absolute;
			right: 0;
			top: -7px;
			background: $wh;
			padding: 5px 10px;
			@include transition(0.2s all);
			&.srch {
				width: calc(100vw - 85px);
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.canvas-menu {
		margin-right: 15px;
	}
	.header-bottom .nav-search {
		margin-right: 0;
		margin-left: 15px;
	}
	.related-header-wrapper {
		margin-bottom: 25px;
		&.scrbtn {
			margin-bottom: 50px;
		}
		.scroll-button {
			top: 100%;
		}
	}
}

@media (max-width: 424px) {
	.product-set-group {
		column-count: unset;
		display: flex;

		.group_item {
			margin-bottom: 0;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	h1, .h1 {
		font-size: 22px;
	}
	.product-markdown-view {
		#button-cart {
			width: 100%;
		}
		.product-meta {
			.cart {
				flex-direction: column;
			}
		}
	}
}

@media (max-width: $screen-phone) {
	.logo-store a span {
		font-size: 3vw;
	}
	.product-special .product-grid .product-block, .product-search .product-grid .product-block {
		width: calc(100% - 30px);
	}
	.product-grid {
		.product-block {
			width: calc(100% - 30px);
		}
		&.presale-prepare-free {
			top: unset;
			bottom: 43px;
		}
	}
}
