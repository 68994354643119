body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	border-radius: 3px;
	background: $wh;
	input.form-control {
		font-style: normal;
		background: transparent;
		border-radius: 0;
		border: none;
		color: $bk;
		@include transition(all 0.2s ease-in-out);
		@include placeholder($tc);
	}
	&.found {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.btn-search {
		display: block;
		background: transparent;
		font-size: 18px !important;
		cursor: pointer;
		@include transition(all 0.2s ease-in-out);
		width: 30px;
		height: 30px;
		padding: 0 5px;
		line-height: 17px;
		border: 0;
		svg {
			fill: $bk;
			@include transition(all 0.2s ease-in-out);
		}
		&:hover svg {
			fill: $tc;
		}
	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

// module cart
#cart {
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
	}
	.icon-cart {
		background: transparent;
		@include size(28px, 28px);
		display: inline-block;
		margin-right: 10px;
		position: relative;
		text-align: center;
	}
	.wrap-cart {
		display: flex;
		align-items: center;
		height: 40px;
		color: $wh;
		span {
			position: relative;
		}
		.cart-total {
			text-transform: none;
			margin-right: 3px;
		}
		.badge {
			top: 0 !important;
			right: 3px;
			padding: 3px;
			min-width: 17px;
			line-height: 11px;
			z-index: 99;
			background: $wh;
			font-weight: 400;
			color: $bk;
			font-size: 11px;
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.cart-inner {
		text-align: right;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}