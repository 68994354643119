 body { 
    .home2{
        .verticalmenu{
            .navbar-nav > li{
                .dropdown-menu {
                    left: auto;
                    right:110%;
                }
                a{
                    .caret{
                        @include rotate(90deg);
                    }
                }
                &:hover{
                    > .dropdown-menu{
                        right:100%;
                        left: auto;
                    }
                }
            }
        }
    }
}    

.arrow{
    @include size(20px,20px);
    line-height:20px !important;
    color:#343434;
    font-size:$font-size-base + 3;
    text-align: center;
    background:$white;
    @include rounded-corners(100%);
}
.pav-verticalmenu{
    font-weight: 400;
    padding: 0;
    background-color: $vertical-menu-content-bg;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid $vertical-border-color;
    border-top: 0;
    display: block; 
    .panel-body{
        background:$vertical-menu-content-bg;
        padding:30px;
    }
    .navbar-default{
        background:transparent;
        border:none;
        margin: 0;
    }
}
.verticalmenu {
    .navbar-collapse{
        margin:0;
        padding:0;
        border: none ;
    }
    .navbar-nav {
        position:static;
        @include clearfloat;
        padding:0;
        > li {
            @include size(100%,auto);
            float: left;
            padding: 0 15px;
            border-bottom:1px solid $border-color;
            &:last-child{
                border-bottom:none;
                padding-bottom:20px;
            }
            &:first-child{
                padding-top:20px;
            }
            > a {
                display: block;
                color: $theme-color-default;
                padding: $vertical-menu-link-padding;
                text-transform: uppercase;
                font-size: $font-size-base - 1;
                font-weight: 400;
                &:hover{
                    color: $white;
                }
                .menu-title{
                    margin-left: 15px;
                }
                .menu-icon{
                    display: block;
                    line-height: 30px;
                    min-height: 30px;
                }
                .fa {
                    text-align: center;
                    line-height: 30px;
                    color: #ccd6dd;
                    font-size: $font-size-base + 6;
                    width: 30px;

                }

                .caret {
                    float: right;
                    @include rotate(-90deg);
                    margin-top: 15px;
                }
            }
            // Hover li

            &.topdropdow:hover {
               /*  position: static; */
            }

            &:hover {
                background:$theme-color;
                color: $white;
                @include transition(all 300ms ease-in-out 0s);
                position:relative;
                @include element-absolute($black);
                @include rounded-corners(3px);
                > a{
                    color: $white;
                }
                .fa{
                    color: $white;
                }
                > .dropdown-menu {
                    top: 0;
                    @include opacity(1);
                    visibility: visible;
                    display: block;
                    left:100%;
                    border:none;
                    margin-left:-1px;
                }
            }
            .parent {
                position: relative;
                &:hover {
                    > .dropdown-menu {
                       right:100%;
                        top: 0;
                        @include opacity(1);
                    }
                }
                &.dropdown-submenu {
                    &:hover {
                        .dropdown-menu {
                            right:115%;
                            display: block;
                            top: 0;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .dropdown-menu {
        right: auto;
        left:110%;
        @include opacity(0);  
        @include transition(all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
        top:0;
        position:absolute;
        visibility: hidden;
        z-index: 999 ;
        display: block;
        min-width: $vertical-sub-menu-width;
        padding: 25px;
        &.level1 {
            .fa {
                display: none;
            }
            margin-top: -1px;
        }
        &.level2 {
            .fa {
                display: none;
            }
        }
        &.level3 {
            .fa {
                display: none;
            }
        }
        ul {
            list-style-type: none;
            padding-left: 0;
            > li {
                > a{
                    font-family: $font-family-base;
                    color: $megamenu-sub-link-color;
                    font-size: $font-size-base - 2;
                    text-transform: uppercase;
                    font-weight: 400;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
        .sidebar{
            box-shadow:none;
            padding:0;
        }
    }
    .widget-heading {
        font-size: $font-size-base + 2;
        margin: 0 0 20px;
    }

    .w-product {
        .product-block{
            text-align: center;
            .image{
                border:1px solid $border-color;
            }
            .name{
                min-height:auto;
                margin-bottom: 0;
            }
        }
        .media-body{
            display: inline-block;
            text-align: center;
        }
    }
    .bg2 {
        div.dropdown-menu {
            background-image: url("#{$image-theme-path-base}bg2.jpg");
            background-position: right bottom;
            background-repeat: no-repeat;
            ul.list-unstyled{
                margin-bottom: 20px;
            }
        }

    }
}